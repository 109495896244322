@import "swiper/swiper-bundle.css";

/*
	Pagination
*/

:root {
  --swiper-pagination-bullet-horizontal-gap: 8px;
  --swiper-pagination-bottom: 8px;
}

.swiper-pagination-wrapper {
  position: absolute;
  bottom: var(--swiper-pagination-bottom);
}

.swiper-pagination {
  @apply z-40;
}

.swiper-pagination-wrapper .swiper-pagination {
  position: relative;
  bottom: 0;
  width: auto;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background: theme("colors.black-01");
}

.swiper-pagination-bullet.swiper-pagination-light {
  background: theme("colors.white-01");
}

.swiper-pagination-bullet-active {
  opacity: 1;
  width: 30px;
  border-radius: 6px;
  transition: width 0.6s cubic-bezier(0, 1, 0.25, 1);
}

/*
	Alignment and Sizing
*/

.centered-slides .swiper-wrapper {
	align-items: center;
}

.swiper-slide {
	width: auto;
}

/*
  Autoplay
*/
.swiper-ticker {
  transition-timing-function: linear!important;
}
