@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  a.active {
    border-left-color: #6863f2;
    background-color: rgba(104, 99, 242, 0.1);
  }

  .smooth-scroll {
    scroll-behavior: smooth;
  }

  .form-input,
  .form-textarea,
  .form-select,
  .form-multiselect,
  .form-input:focus,
  .form-textarea:focus,
  .form-select:focus,
  .form-multiselect:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  .text-size-adjust {
    -webkit-text-size-adjust: 100%;
  }
  .optimize-legibility {
    text-rendering: optimizeLegibility;
  }
  .text-shadow-xs {
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  }
  .no-ligatures {
    font-variant-ligatures: none;
  }

  .stylistic-alternatives {
    font-feature-settings: "ss04" on;
  }

  .h-screen-minus-header {
    height: calc(100vh - theme("spacing.44"));
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    img {
      image-rendering: -webkit-optimize-contrast !important;
    }
  }

  /* Unset for Safari 11+ */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      img {
        image-rendering: unset !important;
      }
    }
  }
}

@layer utilities {
  .hover-underline {
  }

  .hover-underline:hover {
  }

  .hover-underline span {
    @apply inline;
    @apply transition-all;
    @apply relative;
    @apply no-underline;
    @apply bg-no-repeat;
    @apply bg-left-top;
    @apply duration-300;

    @apply bg-gradient-to-b;
    @apply from-current via-transparent;

    background-repeat: no-repeat;
    background-size: 0px 3px;
  }

  .hover-underline:hover span:not(.SecondaryButton__icon),
  .hover-underline-show span:not(.SecondaryButton__icon) {
    background-size: 100% 3px;
  }

}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer utilities {
  .dashed-border-y {
    background-image: linear-gradient(#97a0b5 50%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 2px 15px;
    background-repeat: repeat-y;
  }

  .dashed-border-x {
    background-image: linear-gradient(
      90deg,
      #97a0b5 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 15px 2px;
    background-repeat: repeat-x;
  }
}

@layer base {
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .form-input,
  .form-textarea,
  .form-select,
  .form-multiselect {
    @apply border-transparent;
    @apply px-4 py-2;
  }
  .form-input:focus,
  .form-textarea:focus,
  .form-select:focus,
  .form-multiselect:focus {
    @apply shadow-none outline-none ring-offset-transparent ring-0 ring-offset-0 border-t-transparent border-l-transparent border-r-transparent border-b-current;
  }

  .form-select {
    background-image: url(data:image/svg+xml,%3Csvg%20width%3D%228%22%20height%3D%225%22%20viewBox%3D%220%200%208%205%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M4%205L0.535898%200.5L7.4641%200.5L4%205Z%22%20fill%3D%22%230D1418%22%2F%3E%3C%2Fsvg%3E);
    background-size: 8px 6px;
    background-position: right 1rem center;
  }
}

@layer base {
  @font-face {
    font-family: "TWK Everett";
    src: url("/fonts/TWKEverett-Light-web.woff2") format("woff2"),
      url("/fonts/TWKEverett-Light-web.woff") format("woff"),
      url("/fonts/TWKEverett-Light-web.ttf") format("truetype"),
      url("/fonts/TWKEverett-Light.otf") format("opentype");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: "TWK Everett";
    src: url("/fonts/TWKEverett-Light-web.woff2") format("woff2"),
      url("/fonts/TWKEverett-Light-web.woff") format("woff"),
      url("/fonts/TWKEverett-Light-web.ttf") format("truetype"),
      url("/fonts/TWKEverett-Light.otf") format("opentype");
    font-weight: 300;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: "TWK Everett";
    src: url("/fonts/TWKEverett-Regular-web.woff2") format("woff2"),
      url("/fonts/TWKEverett-Regular-web.woff") format("woff"),
      url("/fonts/TWKEverett-Regular-web.ttf") format("truetype"),
      url("/fonts/TWKEverett-Regular.otf") format("opentype");
    font-weight: normal;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: "TWK Everett";
    src: url("/fonts/TWKEverett-RegularItalic-web.woff2") format("woff2"),
      url("/fonts/TWKEverett-RegularItalic-web.woff") format("woff"),
      url("/fonts/TWKEverett-RegularItalic-web.ttf") format("truetype"),
      url("/fonts/TWKEverett-RegularItalic.otf") format("opentype");
    font-weight: normal;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: "TWK Everett";
    src: url("/fonts/TWKEverett-Medium-web.woff2") format("woff2"),
      url("/fonts/TWKEverett-Medium-web.woff") format("woff"),
      url("/fonts/TWKEverett-Medium-web.ttf") format("truetype"),
      url("/fonts/TWKEverett-Medium.otf") format("opentype");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: "TWK Everett";
    src: url("/fonts/TWKEverett-Medium-web.woff2") format("woff2"),
      url("/fonts/TWKEverett-Medium-web.woff") format("woff"),
      url("/fonts/TWKEverett-Medium-web.ttf") format("truetype"),
      url("/fonts/TWKEverett-Medium.otf") format("opentype");
    font-weight: 500;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: "TWK Everett";
    src: url("/fonts/TWKEverett-Bold-web.woff2") format("woff2"),
      url("/fonts/TWKEverett-Bold-web.woff") format("woff"),
      url("/fonts/TWKEverett-Bold-web.ttf") format("truetype"),
      url("/fonts/TWKEverett-Bold.otf") format("opentype");
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: "TWK Everett";
    src: url("/fonts/TWKEverett-BoldItalic-web.woff2") format("woff2"),
      url("/fonts/TWKEverett-BoldItalic-web.woff") format("woff"),
      url("/fonts/TWKEverett-BoldItalic-web.ttf") format("truetype"),
      url("/fonts/TWKEverett-BoldItalic.otf") format("opentype");
    font-weight: 600;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: "TWK Everett";
    src: url("/fonts/TWKEverett-Bold-web.woff2") format("woff2"),
      url("/fonts/TWKEverett-Bold-web.woff") format("woff"),
      url("/fonts/TWKEverett-Bold-web.ttf") format("truetype"),
      url("/fonts/TWKEverett-Bold.otf") format("opentype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: "TWK Everett";
    src: url("/fonts/TWKEverett-BoldItalic-web.woff2") format("woff2"),
      url("/fonts/TWKEverett-BoldItalic-web.woff") format("woff"),
      url("/fonts/TWKEverett-BoldItalic-web.ttf") format("truetype"),
      url("/fonts/TWKEverett-BoldItalic.otf") format("opentype");
    font-weight: 700;
    font-display: swap;
    font-style: italic;
  }
}
