@import "nprogress/nprogress";


#nprogress .bar {
  background: #0D1418;
}

/* Fancy blur effect */
#nprogress .peg {
  box-shadow: 0 0 10px #0D1418, 0 0 5px #0D1418;
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
display: none;
}
