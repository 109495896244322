@import "flatpickr/dist/flatpickr.css";

input.fpAltInput {
  width: 100%;
  padding: 2px 1rem 5px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  border: var(--chakra-borders-none);
  color: #000000;
  background-color: #FFFFFF;
}
